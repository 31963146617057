@font-face {font-family: 'Open-Sans';
  font-weight: 300;
  src: url('./fonts/webfonts/Open-Sans/OpenSans-Light.ttf') format('truetype');}
@font-face {font-family: 'Open-Sans';
  font-weight: 400;
  src: url('./fonts/webfonts/Open-Sans/OpenSans-Regular.ttf') format('truetype');}
@font-face {font-family: 'Open-Sans';
  font-weight: 500;
  src: url('./fonts/webfonts/Open-Sans/OpenSans-Medium.ttf') format('truetype');}
@font-face {font-family: 'Open-Sans';
  font-weight: 700;
  src: url('./fonts/webfonts/Open-Sans/OpenSans-Bold.ttf') format('truetype');}
