header {
    .institutional-banner-desktop {
        .institutional {
            padding: 4px 0 !important;
        }
    }
    #main-header {
        #logo {
            img {
                max-height: 56px !important;
            }
        }
        #burger-main-menu {
            .sidebar {
                .institutional-banner-burger {
                    .row {
                        .header-footer-col.header-footer-align-right {
                            text-align: left;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

footer {
    .container.default {
        .row {
            .header-footer-col {
                ul {
                    li {
                        &.acceo {
                            a {
                                padding-left: 18px;
                                position: relative;
                                img {
                                    position: absolute;
                                    top: 2px;
                                    left: 0;
                                }
                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                    &.opening {
                        li {
                            list-style-type: disc;
                            margin-left: 20px;
                        }
                    }
                }

                span {
                    &.heading {
                        font-weight: $weight-bold;
                    }
                }
            }
        }
    }
}

#user-profile {
    .user-forms {
        width: 450px !important;
    }
}
